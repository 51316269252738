/* EmotionsLog.css */
@import 'CommonStyles.css';

.message-container {
    font-weight: bold;
}

.percentage-dropdown {
    width: 100%;
    padding: 1rem;
    font-size: 2rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    background-color: #f9f9f9;
    color: #666;
    font-weight: bold;
    text-transform: uppercase;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23666" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.5em;
}

.percentage-dropdown option {
  font-weight: bold;
}

.student-grid {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.student-item {
  display: table-row;
}

.student-name,
.student-data,
.student-top-actions {
  font-size: 1.2vw;
  display: table-cell;
  padding: 10px;
  text-align: start;
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.student-name {
  font-weight: bold;
  width: 25%;
}
.student-data {
  width: 25%;
}
.student-top-actions {
  width: auto;
}

.top-actions-container {
    display: flex;
    gap: 3vw;
}
  
.top-action-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
.top-action-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}
  
.top-action-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
  
.hover-text {
  position: absolute;
  color: black;
  padding: 5px;
  border-radius: 5px;
  white-space: wrap;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
  width: 10vw;
  text-align: center;
}
  
.top-action-wrapper:hover .hover-text {
  opacity: 1;
  z-index: 2;

}
.top-action-count {
  text-align: center;
  margin-top: 5px;
  font-size: 12px;
}  

@media(max-width:600px){
    .percentage-dropdown {
        font-size: 1.125rem;
    }
    .student-name,
    .student-data,
    .student-top-actions {
        font-size: 4vw;
        display: table-cell;
        padding: 10px;
        text-align: start;
        vertical-align: middle;
        border-bottom: 1px solid #ccc;
    }
    .hover-text {
      width: 25vw;
    }
}