@media (min-width: 601px) {

    .achievementPageContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
    }
    .achievementheaders{
        font-weight: bold;
    }
    .achievementheadersubtext{
        font-weight: bold;
    }

    .achievementheadersubtextweek{
        margin-top: 2vh;
        /* font-size: 1.2vw; */
        font-weight: bold;
    }
    .congratsSubheader{
        width: 100%;
        text-align: center;
        font-size: 22px;
        margin-bottom: 20px;
    }
    
    .achievementSectionContainer {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 2vh 0;
        width: 100%;
        max-width: 45vw;
    }
    .achievementSContainerRightSide {
        justify-content: start;
        /* position: relative; */
        /* margin-left: 8vw; */
        width: 100%;
        margin: 2vh 0; /* Default margin for all elements */
        display: flex;
        flex-direction: column;
    }
    .achievementExplanationP {
        display: block;
    }

    .achievementSectionContainer:first-of-type {
        margin-top: 2vh; /* Specific margin for the first element */
    }

    .myAchievementsSideIcon{
        /* position: absolute; */
        /* left: -8vw; */
        height:10vh;
        width:auto;
        margin-right: 1vh;
    }

    #myAchievementsSideIconStreak{
        /* position: absolute; */
        /* left: -6.5vw; */
        height:10vh;
        width:auto;
        /* margin-right: 5vh; */
        padding: 0 2.5vh;
    }
    
    .streaksDisplayContainer{
      display:flex;
      width: 40vw;
      overflow-x: auto;
      padding: 10px ;
      white-space: nowrap;
    }

    .badgeItemLabel{
        text-align:center;
        white-space: nowrap;
    }
    
    .streakItemImage{
        height:7.8vw;
        width:auto;
        display: inline-block;
        position: relative;
    }

    .maxStreakDisplay{
        width:3.622448979591837vw;
        height:5vw;
        display:absolute;
    }

    .badgesDisplay {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1vh;
        width: 100%;
      }

    .badgeDisplayItem{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .badgeImageWellnessBadges{
        width:7vw;
        height:auto;
        cursor: pointer;
        margin-right: 2vw;
    }

    .toggleableBadgeDescription.notSelected{
        display:none;
    }

    .toggleableBadgeDescription.selected{
        margin-top: 2vh;
        display:flex;
        margin-bottom: 2vh;
    }
    
    .streakDisplayItem {
      position: relative;
      display: flex;
      margin-right: 2vw;
    }
    
    .daysText {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.6vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Medium';
    }
    .weeksText {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.6vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Medium Cond';
    }
    
    .yearText {
      position: absolute;
      top: 25%;
      left: 55%;
      font-size: 0.5vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Demi Cond';
    }
    
    .yearTextWeekStreak {
      position: absolute;
      top: 25%;
      left: 55%;
      font-size: 0.5vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Demi Cond';
    }
    .tenYearTextWeekStreak {
      position: absolute;
      top: 25%;
      left: 65%;
      font-size: 0.45vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Demi Cond';
    }
    
    .yearDaysText {
      position: absolute;
      top: 49.5%;
      left: 55%;
      transform: translateY(-50%);
      font-size: 0.55vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Medium Cond';
    }
    .yearWeeksText {
      position: absolute;
      top: 47.5%;
      left: 45%;
      transform: translateY(-50%);
      font-size: 0.55vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Medium Cond';
    }
}

@media (max-width: 600px) {
    .achievementPageContainer {
        display: flex;
        flex-direction: column;
        width: 90vw;
        margin: 0 auto;
        height: 60vh;
    }

    .congratsSubheader {
        width: 100%;
        text-align: center;
        font-size: 2.2vh;
        margin-bottom: 1vh;
        font-weight: bold;
    }

    .achievementSectionContainer {
        display: flex;
        flex-direction: row; 
        /* justify-content: space-between; */
        margin: 1vh 0;
        width: 100%;
        /* padding: 0 2vw; */
    }

    .achievementSectionContainerRightSide {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* /width: 70%; */
        text-align: left;
    }

    .achievementheaders {
        font-size: 4vw;
        position: relative;
        font-weight: bold;
    }

    .achievementheadersubtext,  .achievementheadersubtextweek {
        font-size: 3.8vw;
        margin: 0;
        position: relative;
    }
    .achievementheadersubtextweek {
        margin-top: 1vh;
    }
    .achievementExplanationP {
        font-size: 3.5vw;
        line-height: 1.4;
        display: none;
        transition: opacity 0.3s ease;
    }
    .achievementheaders:hover ~ .achievementExplanationP {
        display: block;
    }
    .achievementheadersubtext:hover ~ .achievementExplanationP {
        display: block;
    }
    .achievementheadersubtextweek:hover ~ .achievementExplanationP {
        display: block;
    }

    .myAchievementsSideIcon {
        height: 34px;
        width: auto;
        margin-right: 3vw;
    }

    #myAchievementsSideIconStreak {
        height: 34px;
        width: auto;
        margin-right: 5vw;
        margin-left: 2vw;
    }

    .streaksDisplayContainer {
        display: flex;
        width: 100%;
        overflow-x: auto;
        padding: 10px 0;
        white-space: nowrap;
        max-width: 80vw;
    }

    .badgeItemLabel {
        text-align: center;
        font-size: 1.6vh;
        margin-bottom: 0;
    }

    .streakItemImage {
        height: 19vw;
        width: auto;
        display: inline-block;
    }

    .maxStreakDisplay {
        width: 3.622448979591837vw;
        height: 5vw;
    }

    .badgesDisplay {
        margin-right: 0;
        display: flex;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        /* max-width: 65vw; */
        max-width: 75vw;
    }

    .badgeDisplayItem {
        flex: 0 0 calc(33.33% - 10px);
        margin: 0 10px;
    }

    .badgeImageWellnessBadges {
        width: 20vw;
        height: auto;
        cursor: pointer;
    }

    .toggleableBadgeDescription.notSelected {
        display: none;
    }

    .toggleableBadgeDescription.selected {
        display: flex;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    .streakDisplayItem {
      display: flex;
      margin-right: 3vw;
      position: relative;
    }
    
    .daysText {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.6vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Medium Cond';
    }
    .weeksText {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size:1.6vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Medium Cond';
    }
    
    .yearText {
      position: absolute;
      top: 25%;
      left: 55%;
      font-size: 1.3vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Demi Cond';
    }
    
    .yearTextWeekStreak {
      position: absolute;
      top: 25%;
      left: 55%;
      font-size: 1.2vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Demi Cond';
    }
    
    .yearDaysText {
      position: absolute;
      top: 50%;
      left: 54%;
      transform: translateY(-50%);
      font-size: 1.3vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Medium Cond';
    }
    .yearWeeksText {
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translateY(-50%);
      font-size: 1.2vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Medium Cond';
    }
    .tenYearTextWeekStreak {
      position: absolute;
      top: 25%;
      left: 66%;
      font-size: 1.1vw;
      font-weight: bolder;
      font-family: 'Franklin Gothic Demi Cond';
    }
}


