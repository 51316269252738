.BigFiveWellnessTab {
    width: 60vw;
    height: 82vh;
    margin: 0vh auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.big5-container {
    font-family: Arial, sans-serif;
    margin: 0 5vw;
    text-align: center;
}
  
.big5-header {
    font-size: 16px;
    position: relative;
    margin-bottom: 1vh;
}

/* .big5-content {
    margin-bottom: 1vh;
} */

.info-icon, .hide-text {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
    margin-top: 1vh;
    color: #007bff;
}

.big-five-data-display {
    position: relative;
    z-index: 1;
}

.filter-controls {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    gap: 2vw;
}

.filter-column {
    position: relative;
    width: calc(50% - 1vw);
}

.filter-label {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #888;
    font-size: 14px;
    margin-bottom: 5px;
}

.filter-icon {
    font-size: 16px;
}

#helpfulFilter, .time-selector-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
}

.time-selector-button {
    text-align: left;
    cursor: pointer;
}

.time-selector-button:hover {
    background-color: #f0f0f0;
}

.time-selector-popup {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    width: 100%;
    display: none;
}

.time-selector-popup.open {
    display: block;
}

.popup-content {
    padding: 20px;
    max-height: 400px;
    overflow-y: auto;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.time-selector {
    border: 1px solid #ccc;
    padding: 10px;
    font-family: Arial, sans-serif;
    border-radius: 5px;
    background-color: white;
}

.main-option {
    margin-left: 5px;
}

.time-range {
    font-weight: normal;
}

.sub-options {
    margin-left: 20px;
}

.sub-options label {
    display: block;
    margin-top: 5px;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #ccc;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    vertical-align: middle;
    position: relative;
}

input[type="checkbox"]:checked {
    background-color: #5B9CD6;
    border-color: #5B9CD6;
}

input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -60%) rotate(45deg);
}

label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: normal;
}

.table-container {
    overflow-y: scroll;
    height: 65vh;
}

.big-five-data-display table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    table-layout:auto;
}

.big-five-data-display th, .big-five-data-display td {
    border: none;
    padding: 15px 10px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.big-five-data-display thead tr {
    background-color: white;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 1;
}

.big-five-data-display tr {
    height: 60px;
}

.icon {
    width: 2rem;
    height: 2rem;
    margin-right: 5px;
    vertical-align: middle;
}

.emotion-icon-big5 {
    width: 30px;
    height: auto;
    margin-right: 5px;
    vertical-align: middle;
    padding: 10px 5px 10px 0px;
}

.action-icon {
    width: auto;
    height: 3rem;
    margin-right: 5px;
    vertical-align: middle;
}

.action-cell {
    max-width: 5vw; /* Adjust the width as needed */
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .name-cell {
    max-width: fit-content;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .emotion-cell {
    max-width: 4vw; /* Adjust the width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cell-header {
    display: none;
  }
  .cell-content {
    display: flex;
    white-space: wrap;
    align-items: center;
  }
.wellness-strategy-image-container {
    position: relative;
    cursor: pointer;
}

.wellness-strategy-hover-text {
    background-color: white;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    white-space: wrap;
}


@media (max-width: 600px) {
    th {
     display: none;
    }
    .big-five-data-display thead {
        display: none;
    }
    .cell-header {
        display: block;
        font-weight: bold;
        text-transform: uppercase;
    }
    td {
        display: grid;
        grid-template-columns: 40vw 40vw;
        padding: 4px 4px;
        text-align: left;
    }
    .cell-content {
        display: flex;
        justify-content: flex-start;
    }
    .cell-content p {
        white-space: wrap;
    }

    table,
    thead,
    tbody,
    tr {
        display: block;
        width: 100%;
    }

    tr {
        padding: 10px;
        border: 1px solid #ccc;
    }
    .emotion-cell {
        max-width: 20vw;
    }

    td img.action-icon {
         width: 25px;
         height: 25px;
         margin-right: 5px;
         margin-left: -1px;
         vertical-align: middle;

    }
    td .icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        /* margin-left: -2px; */
    }

    .emotion-icon-big5 {
        width: 23px;
        height: 23px;
        margin-right: 5px;
        vertical-align: middle;
        padding: 0;
        margin-left: -1px;
    }
 
    .action-cell {
         align-items: center;
         max-width: 100%;
         white-space: normal;
         overflow: visible;
    }
 
    .emotion-cell {
         align-items: center;
         max-width: 100%;
         white-space: normal;
         overflow: visible;
    }
 
    .table-container tr {
         height: auto;
    }
 
    .thumbs-up {
        font-size: 1.2em;
        width: 23px;
        height: 16px;
        margin-left: 1px;
    }

    .big-five-data-display{
        margin-bottom: auto;
        z-index: 0;
    }
    
    .time-selector-popup {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 10px rgba(0,0,0,0.1);
        width: 50vw;
        display: none;
    }
    .name-cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: fit-content;
    }

    .BigFiveWellnessTab {
        width: 90vw;
        height: 100%;
        margin: 0vh auto;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        /* overflow-y: auto; */
    }
    .table-container {
        overflow-y: scroll;
        height: 100%;
        font-size: 1.5vh;
    }
    .big5-container {
        text-align: start;
        margin: 0 1rem;
    }
    .big5-header {
        font-size: 2vh;
        margin-bottom: 0;
    }
    .big5-content {
        margin-bottom: 1vh;
    }
    
    #helpfulFilter, .time-selector-button {
        font-size: 1.5vh;
        padding: 5px;
        border-radius: 0;
        max-width: 40vw;
        cursor: pointer;
    }
    .filter-controls {
        margin-bottom: 2vh;
    }
    .big5-content p {
        margin-bottom: 0;
        font-size: 2vh;
    }
    .big-five-data-display td {
        border: none;
        padding: 4px 0;
        text-align: left;
        white-space: wrap;
    }
}
