.classandactions {
  display: flex;
  flex-direction: column;
  width: 93vw;
  align-items: center;
  margin-left: 7vw;
  height: fit-content
}
.anythingineed {
  font-size: 2.2vw;
  margin-top: 15vh;
  font-weight: bold;
}
.greenrow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 70vw;
  height: fit-content;
  gap: 2vw;
  margin-bottom: 2vw;
}
.bluerow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 70vw;
  height: fit-content;
  gap: 2vw;
  margin-bottom: 2vw;
}
.purplerow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 70vw;
  height: fit-content;
  gap: 2vw;
  margin-bottom: 2vw;
}
.actions {
  object-fit: contain;
  padding: 10px 10px 0 10px;
  cursor: pointer;
}
.outeractions{
  background-color: #dcd9d6;
  display:flex;
  flex-direction: row;
}
.emotionscolumn{
  display:flex;
  flex-direction: column;
}
.imgTextBoxRow{
  display:flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
}
.submitaction{
  width: 10vw;
  height: 5vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: white;
  background-color: #4cc68d;
}
.othertextbox{
  height: 2.5vh;
  width: 12vw;
  margin-top: 2vh;
}
.green-circle-container,
.blue-circle-container,
.purple-circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25vh;
  width: 25vh;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}
.green-circle-container {
  background-color: #4CC68D;
}

.blue-circle-container {
  background-color: #5B9BD5;
}

.purple-circle-container {
  background-color: #BD92E4;
}

.keep-building-text {
  font-size: 1.8rem;
  font-weight: bolder;
  font-family: 'Roboto';
  color: white;
  white-space: normal;
  max-width: 60%;
  text-align: center;
  margin: 10px 12px 12px 12px;
  line-height: 1em;

  /* overflow: hidden; */

  /* display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */ 
  /* max-height: 3.6em;
  line-height: 1.1em;
  /* word-break: break-word; */
}
.tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 2rem;
  min-width: 12vw;
  text-align: center;
  z-index: 2;
  transition: 0.2s ease-in-out;
  pointer-events: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.green-circle-container:hover .tooltip,
.blue-circle-container:hover .tooltip,
.purple-circle-container:hover .tooltip {
  opacity: 1;
}


@media(max-width:600px){
  .classandactions {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    align-items: center;
    margin-left: 0;
  }
  .anythingineed {
    font-size: 4vw;
    margin-top: 15vh;
    font-weight: bold;
  }
  .greenrow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: fit-content;
    gap: 4vw;
    margin-top: 4vw;
    margin-bottom: 4vw;
  }
  .bluerow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: fit-content;
    margin-top: 4vw;
    gap: 4vw;
    /* margin-bottom: 4vw; */
  }
  .purplerow {
    margin-top: 4vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: fit-content;
    gap: 4vw;
    /* margin-bottom: 4vw; */
  }
  .outeractions{
    background-color: #F5FBFF;
    display:flex;
    flex-direction: row;
  }
  .emotionscolumn{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .imgTextBoxRow{
    display:flex;
    flex-direction: column;
    height: fit-content;
    align-items: center;
  }
  .submitaction{
    width: 20vw;
    height: 5vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
    color: white;
    background-color: #4cc68d;
  }
  .othertextbox{
    height: 2.5vh;
    width: 55vw;
    margin-top: 2vh;
    text-align: center;
  }
  .green-circle-container,
  .blue-circle-container,
  .purple-circle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 21vh;
    width: 21vh;
    border-radius: 50%;
    cursor: pointer;
  }

  .keep-building-text {
    font-size: 1.5rem;
    font-weight: bolder;
    color: white;
    white-space: normal;
    max-width: 65%;
    text-align: center;
    margin: 10px 20px 20px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word; 
  }
  .tooltip {
    font-size: 1.5rem;
    width: 35vw;
  }
}