.action-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50vh;
  height: 50vh;
  margin-top: 8vh;
  text-align: center;
  color: #fff;
  overflow: hidden;
  position: relative;
}

.perform-circle-text {
  font-size: 2.8rem;
  font-family: 'Roboto';
  font-weight: bolder;
  text-align: center;
  white-space: normal;
  padding: 10px;
  max-width: 65%; 
  overflow: hidden;
  line-height: 1.1em;
}

@media (min-width: 601px) {
  .practicewellnesstext {
    font-size: 2vw;
    margin-top: 8vh;
    font-weight: bold;
  }
  
  .outerperformaction {
    background-color: #dcd9d6;
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  .altwellness {
    font-size: 2vw;
    color: #4cc68d;
    margin-top: 4vh;
    font-weight: bold;
  }
  .linearprogresspractice{
    width: 40%;
    margin-top: 5vh
  }
}
@media (max-width: 600px) {
  .outerperformaction {
    background-color: #F5FBFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100vh;
  }
  .altwellness {
    font-size: 4vw;
    color: #4cc68d;
    margin-top: 4vh;
    font-weight: bold;
  }
  .practicewellnesstext {
    font-size: 4vw;
    margin-top: 12vh;
    font-weight: bold;
  }
  .linearprogresspractice{
    width: 60vw;
    margin-top: 4vh
  }
  .action-circle {
    width: 42vh;
    height: 42vh;
  }
  .perform-circle-text {
    font-size: 2.5rem;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 3.6em;
    line-height: 1em;
    word-break: break-word;
  }
}
