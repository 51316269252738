.optionsoutercolumn{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5vw;
  background-color: #F4F4F3;
  height: 100vh;
  overflow-y: hidden;
}
.optionsinnercolumn {
  background-color: white;
  width:50vw;
  height: 96vh;
  padding: 0 7vw 0 7vw;
  margin: 2vh 0;
}

.settings-container {
  height: 90vh;
}

.options-title {
  font-size: 2.2vh;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.optionsimg {
  height: 10vh;
  width: auto;
  border-radius: 0.5vh;
}

.settings-item {
  display: flex;
  flex-direction: column;
  gap: 0.9vh;
  padding: 1.2vh;
}

.settings-title-row {
  display: flex;
  align-items: center;
  gap: 0.9vh;
}

.settings-icon {
  width: 1.6vh;
  height: 1.6vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-title {
  font-size: 1.6vh;
  font-weight: 700;
}

.display-name-title {
  font-size: 2vh;
  font-weight: 700;
}

.settings-description {
  font-size: 1.6vh;
  font-weight: 400;
}

.display-name-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 25vw;
}
.name-email-row {
  display: flex;
  flex-direction: column;
  gap: 0.9vh;
}
.name-change {
  font-size: 1.4vh;
  font-weight: 400;
  color: #0000EE;
}
.optionsemail {
  font-size: 1.8vh;
  font-weight: 700;
}
.optionselect{
  height: 3.6vh;
  width: 8vw;
  font-size: 1.7vh;
  font-weight: 400;
  border-radius: 0.5vh;
}
.settings-checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.9vh;
}

input[type="checkbox"] {
  width: 1.3vh;
  height: 1.3vh;
  /* width: 12px;
  height: 12px; */
  border: 2px solid #D9D9D9;
  background-color: #D9D9D9;
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 0;
}

input[type="checkbox"]:checked {
  border-color: #007BFF;
  background-color: #007BFF;
}
.buttonrow{
  margin-bottom: 1.6vh;
  margin-top: 0.5vh;
  padding: 1.2vh;
}
.logout{
  width: 7vw;
  height: 4.1vh;
  background-color: #256AA6;
  color:white;
  font-weight: 700;
  font-size: 1.8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5vh;
  border: 0.2vh solid #DDDDDD;
}
/* Edit Name Component */
.name-change-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
  opacity: 0;
  pointer-events: none;
  z-index: 1000; 
}

.name-change-overlay.visible {
  opacity: 1;
  pointer-events: all;
}

.name-change-container {
  width: 33vw;
  height: 36vh;
  padding: 2.2vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 20vh;
  left: 36vw;
}

.name-change-title {
  margin-top: 0;
  font-size: 2.2vh;
  font-weight: 400;
}

.name-change-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.6vh;
}

.name-change-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.2vh;
}
.name-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.name-change-label {
  font-size: 1.8vh;
  font-weight: 700;
  justify-content: flex-start;
  padding: 1vh 0;
}

.name-change-input {
  width: 30vw;
  height: 3.6vh;
  flex: 1;
  padding: 0.9vh;
  font-size: 1.8vh;
  border: 1px solid #DDDDDD;
}

.name-change-submit {
  padding: 0.9vh;
  font-size: 1.5vh;
  font-weight: 700;
  color: white;
  background-color: #256AA6;
  cursor: pointer;
  text-align: center;
  transition: filter 0.3s ease;
  height: 4vh;
  width: 12vw;
}

.name-change-submit.disabled {
  filter: grayscale(1);
  cursor: default;
}

@media(max-width:600px){
  .optionsoutercolumn{
    display: flex;
    flex-direction: column;
    margin-left: 0;
    height: 100vh;
  }
  .settings-container {
    max-height: 95vh;
    overflow-y: auto; 
  }
  .email-name-component {
    flex-direction: column;
    align-items: start;
    gap: 0.9vh;
  }
  .buttonrow {
    margin-bottom: 2vh;
    margin-top: 0;
    padding: 1.2vh;
  }
  .name-change-container {
    width: 90vw;
    top: 12vh;
    left: 5vw;
  }
  .name-change-input {
    width: 80vw;
    height: 10vh;
  }
  .name-change-submit {
    width: 40vw;
  }
  .optionsinnercolumn {
    width: 100vw;
    height: 100vh;
    padding: 0 10px 0 10px;
    margin: 0;
  }
  .optionselect {
    width: 30vw;
  }
  .logout {
    width: 30vw;
  }
  .options-title {
    margin-top: 7.2vh;
  }
  .display-name-row {
    width: 65vw;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.9vh;
  }
}

@media (max-width: 600px) {
  .optionsoutercolumn {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    height: 100vh;
  }
  .settings-container {
    max-height: 95vh;
    overflow-y: auto; 
  }
  .email-name-component {
    flex-direction: column;
    align-items: start;
    gap: 0.9vh;
  }
  .buttonrow {
    margin-bottom: 2vh;
    margin-top: 0;
    padding: 1.2vh;
  }
  .name-change-container {
    width: 90vw;
    top: 12vh;
    left: 5vw;
  }
  .name-change-input {
    width: 80vw;
    height: 10vh;
  }
  .name-change-submit {
    width: 40vw;
  }
  .optionsinnercolumn {
    width: 100vw;
    height: 100vh;
    padding: 0 10px 0 10px;
    margin: 0;
  }
  .optionselect {
    width: 30vw;
  }
  .logout {
    width: 30vw;
  }
  .options-title {
    margin-top: 7.2vh;
  }
}

/* Tablet view */
@media (min-width: 601px) and (max-width: 1024px) {
  .email-name-component {
    flex-direction: row;
    align-items: center;
    gap: 2vw;
  }
  .buttonrow {
    margin-bottom: 2vh;
    margin-top: 1vh;
    padding: 1.5vh;
  }
  .name-change-container {
    width: 70vw;
    top: 10vh;
    left: 17vw;
  }
  .name-change-input {
    width: 60vw;
    height: 8vh;
  }
  .name-change-submit {
    width: 40vw;
  }
  .optionsinnercolumn {
    width: 80vw;
    padding: 0 15px 0 15px;
  }
  .optionselect {
    width: 25vw;
  }
  .logout {
    width: 25vw;
  }
  .options-title {
    margin-top: 2vh;
  }
  .display-name-row {
    width: 45vw;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.9vh;
  }
}
