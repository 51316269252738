.sidebar {
  z-index: 1;
}

@media (min-width: 601px) {
  .sidebar {
    display: flex;
    flex-direction: column;
    width: 7vw;
    align-items: center;
    height: 100vh;
    background-color: #256aa6; 
    position: fixed;
    transition: width 0.5s;
  }
  .sidebar:hover {
    transition: width 0.5s;
    width: 13vw;
  }
  .sidebar:hover .icontexthome,
  .sidebar:hover .icontext{
    display: inline;
    white-space: nowrap;
  }
  .sidebarrow {
    z-index: 2;
  }
  .styleiconstudenthome,
  .styleiconteacherhome,
  .styleiconadminhome,
  .styleiconwellnesshome {
    margin-left: 1vw;
    font-size: 2.5em;
    color: white;
    margin-right: 1vw;
  }
  .styleiconadmin,
  .styleiconstudent,
  .styleiconteacher,
  .styleiconwellness {
    color: white;
    margin:0.5em;
    font-size: 2em;
  }
  /*    */
  .icontext {
    font-size: 1vw;
    margin-top: 1vh;
    display: none;
  }
  .icontexthome {
    margin-top: 1vh;
    font-size: 1vw;
    display: none;
  }
  .icontextrowstudent {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
  }
  .icontextrowteacher {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
  }
  .icontextrowadmin {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
  }
  .icontextrowwellness{
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 7vw;
    transition: background-color 0.3s;
    transition: width 0.5s;
  }
  .icontextrowteacher,
  .icontextrowadmin {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 7vw;
    height: 5em;
    transition: background-color 0.3s;
    transition: width 0.5s;
  }
  .icontextrowstudent {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 7vw;
    height: 6.5em;
    transition: background-color 0.3s;
    transition: width 0.5s;
  }
  .icontextrowwellness:hover,
  .icontextrowteacher:hover,
  .icontextrowadmin:hover,
  .icontextrowstudent:hover{
    background-color: #add8e6;
    cursor: pointer;
  }

  /* .alignMenuOptions a.active .icontextrowwellness,
  .alignMenuOptions a.active .icontextrowteacher,
  .alignMenuOptions a.active .icontextrowadmin,
  .alignMenuOptions a.active .icontextrowstudent{
    background-color: #add8e6;
  } */
  .sidebar:hover .icontextrowwellness,
  .sidebar:hover .icontextrowteacher,
  .sidebar:hover .icontextrowadmin,
  .sidebar:hover .icontextrowstudent{
    justify-content: flex-start;
    transition: width 0.5s;
    width: 13vw;
  }
  .sidebarlogo {
    height: 5vw;
    margin: auto;
    display: block;
  }
  .sidebar:hover .sidebarlogo {
    margin-left: 1vw;
  }
  .patentpending {
    margin-top: 1vh;
    font-size: 1vw;
    color: white;
  }
  .growthwell {
    font-size: 1vw;
    color: white;
    position: fixed;
    bottom: 0;
    margin-bottom: 0.5vh;
  }
  .sidebarcolumn {
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
  }
  .account-type {
    margin-bottom: 1vh;
  }
  .schoolanddistrict {
    text-align: center;
    color: white;
    margin-bottom: 1vh;
    font-size: 1.2vw;
    font-weight: bold;
  }

  .alignMenuOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }
  .alignMenuOptions a {
    text-decoration: none;
  }
  .sidebar:hover .schoolanddistrict,
  .sidebar:hover .growthwell {
    display: block;
    padding: 1vw;
    font-size: 0.8vw;
    font-weight: bolder;
  }
  .schoolanddistrict,
  .growthwell {
    display: none;
  }
  .ham {
    display: none;
  }
  
}

/* ****************************************************** Screens less than 600px *******************************************************************************************************************/
@media (max-width: 600px) {
  .sidebarrow {
    margin-top: 10vh;
  }
  .styleiconstudenthome,
  .styleiconteacherhome,
  .styleiconadminhome,
  .styleiconwellnesshome {
    font-size: 2em;
    color: white;
    margin:0.5em;
  }
  .styleiconadmin,
  .styleiconstudent,
  .styleiconteacher,
  .styleiconwellness {
    color: white;
    margin:0.5em;
    font-size: 2em;
  }
  .icontextrowstudent,
  .icontextrowteacher,
  .icontextrowadmin,
  .icontextrowhome,
  .icontextrowwellness {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: flex-start;
    align-items: center;
  }
  .icontext,
  .icontexthome {
    display: inline;
    white-space: nowrap;
    color: white;
    font-size: 3vh;
    margin-top: 1vh;

  }

  .sidebarlogo {
    height: 0vw;
  }
  .subtab-logo {
    height: 7.2vh;
    width:auto;
    margin: auto;
  }
  .growthwell{
    display: none;
  }
  .schoolanddistrict {
    font-size: 0vw;
    height: 0vh;
    width: 0vh;
  }
  
  /* off-screen-menu */
  .sidebarcolumn {
    background-color:  #256aa6;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    display: flex;
    flex-direction: column;
    align-items: center;    
    text-align: center;
    transition: .3s ease;
    z-index: 100;
  }
  .sidebarcolumn.active {
    left: 0;
  }
  .ham {
    padding: 0 2vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 70%;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .ham-menu {
    height: 6vh;
    width: 5vh;
    margin-right: auto;
    position: relative;
    z-index: 100;
  }
  .ham-menu span {
    height: 0.5vh;
    width: 100%;
    background-color: #000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: .3s ease;
  }
  .ham-menu span:nth-child(1) {
    top: 25%;
  }
  .ham-menu span:nth-child(3) {
    top: 75%;
  }
  .ham-menu.active span {
    background-color: white;
  }
  .ham-menu.active span:nth-child(1) {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .ham-menu.active span:nth-child(2) {
    opacity: 0;
  }
  .ham-menu.active span:nth-child(3) {
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .sidebarcolumn.active ~ .ham .subtab-logo {
    display: none;
  }
  .sidebarcolumn.active ~ .ham .ham-menu {
    z-index: 1000;
  }
  .ham-menu.active {
    position: relative;
    z-index: 1000;
  }
  a {
    text-decoration: none;
  }
}
