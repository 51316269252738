.circle-with-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  margin: 1vh;
}

.circle-container {
  position: relative;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.circle-image {
  object-fit: contain;
  padding: 3px 3px 0 3px;
  cursor: pointer;
}

.circle-text {
  font-family: 'Roboto';
  font-weight: bolder;
  color: white;
  margin: 5px 12px 5px 12px;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  text-align: center;
  max-height: 3.6em;
  line-height: 1em;
  word-break: break-word;
}

.hover-practice-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: none;
  z-index: 2;
  min-width: 7vw;
  font-size: 1.8rem;
  overflow: hidden;
}

.circle-container:hover .hover-practice-text {
  display: block;
}

.circle-container:hover {
  transform: scale(1.1);
}

.circle-caption {
  margin-top: 1vh;
  margin-bottom: 0;
  font-size: 14px;
  color: #333;
  font-weight: bold;
}
.history-circle-with-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.history-circle-container {
  position: relative;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
}

.history-circle-container:hover {
  transform: scale(1.1);
}

.history-circle-image {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.history-hover-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 1.5rem;
  z-index: 999;
  width: 10vw;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  overflow: hidden;

}

.history-circle-container:hover .history-hover-text {
  opacity: 1;
  transform: translateY(0%);
}
@media(max-width:600px){
  .circle-caption {
    font-size: 1.5vh;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .circle-text{
    max-width: 90%;
    margin: 3px 10px 3px 10px;
  }
  .hover-practice-text {
    width: 25vw;
    font-size: 1.5rem;
  }
  .history-circle-container {
    height: 24px;
    width: 24px;
  }
  .history-hover-text {
    width: 30vw;
  }
}